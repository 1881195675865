<template>
  <div class="about">
    <div class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-3">Videos One</h1>          
        </div>
      </div>
    </div>
    <div class="container">
      Video One
    </div>
  </div>
</template>
<script>
export default {
  components: {
  }
}
</script>

<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
</style>